import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Función para enviar datos a tu servicio de análisis
const sendToAnalytics = (metric) => {
  // Implementa aquí la lógica para enviar los datos a tu servicio de análisis
  console.log(metric);
};

reportWebVitals(sendToAnalytics);