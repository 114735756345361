import React, { useState, useEffect, useCallback } from "react";
import { connectSocket, searchEmails } from "../services/socketService";
import Modal from "./Modal";

const SERVICES = {
  NETFLIX: {
    name: "NETFLIX",
    description: "Recupera tu código de acceso temporal de Netflix",
    icon: "🎬",
    color: "bg-red-600",
    key: "netflix",
  },
  DISNEY: {
    name: "DISNEY+",
    description: "Obtén tu código de acceso único para Disney+",
    icon: "🏰",
    color: "bg-blue-600",
    key: "disney",
  },
  AMAZON: {
    name: "AMAZON PRIME",
    description: "Verifica el intento de inicio de sesión en Amazon",
    icon: "📦",
    color: "bg-[#FF9900]",
    key: "amazon",
  },
};

const SUBJECT_FILTERS = {
  netflix: [
    "Netflix: Tu código de inicio de sesión",
    "Importante: Cómo actualizar tu Hogar con Netflix",
    "Tu código de acceso temporal de Netflix",
    "Netflix: Your sign-in code",
  ],
  disney: [
    "Your one-time passcode for Disney+",
    "Tu código de acceso único para Disney+",
  ],
  amazon: [
    "amazon.com: intento de inicio de sesión",
    "amazon.com: Sign-in attempt",
    "amazon.com: Sign-in",
    "Fwd: amazon.com: Intento de inicio de sesión",
  ],
};

const EmailSearch = () => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    content: { text: "", html: "", code: "" },
  });
  const [emailInput, setEmailInput] = useState("");
  const [selectedService, setSelectedService] = useState(SERVICES.NETFLIX);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    connectSocket((status) => setIsConnected(status === "connected"));
  }, []);

  const extractCode = useCallback((html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const xpathResult = doc.evaluate(
      '//*[@id="background"]/tbody/tr[1]/td/table/tbody/tr[2]/td/table/tbody/tr[1]/td/table/tbody/tr[3]/td/text()',
      doc,
      null,
      XPathResult.STRING_TYPE,
      null
    );
    const code = xpathResult.stringValue.trim();
    return code.match(/\d{6}/) ? code : null;
  }, []);

  const searchCode = useCallback(async () => {
    if (!emailInput || !selectedService) return;
    setIsLoading(true);
    try {
      const results = await searchEmails(emailInput, selectedService.key);
      const codeEmail = results.find((email) =>
        SUBJECT_FILTERS[selectedService.key].some((filter) =>
          email.subject.toLowerCase().includes(filter.toLowerCase())
        )
      );

      if (codeEmail) {
        const code = extractCode(codeEmail.html);
        setModalState({
          isOpen: true,
          content: {
            text: code
              ? `Código encontrado: ${code}\n\nAsunto: ${codeEmail.subject}`
              : "No se pudo extraer el código.",
            html: codeEmail.html,
            code: code || "",
          },
        });
      } else {
        setModalState({
          isOpen: true,
          content: {
            text: `No se encontró un correo con código de acceso de ${selectedService.name}.`,
            html: "",
            code: "",
          },
        });
      }
    } catch (error) {
      console.error("Error al buscar emails:", error);
      setModalState({
        isOpen: true,
        content: {
          text: "Ocurrió un error al buscar los emails.",
          html: "",
          code: "",
        },
      });
    }
    setIsLoading(false);
  }, [emailInput, selectedService, extractCode]);

  const copyToClipboard = useCallback(() => {
    if (modalState.content.code) {
      navigator.clipboard
        .writeText(modalState.content.code)
        .then(() => {
          setShowNotification(true);
          setTimeout(() => setShowNotification(false), 2000);
        })
        .catch((err) => console.error("Error al copiar: ", err));
    }
  }, [modalState.content.code]);

  const closeModal = useCallback(() => {
    setModalState((prev) => ({ ...prev, isOpen: false }));
  }, []);

  return (
    <div className="bg-gray-900 text-gray-100 p-4 sm:p-8">
      <h1 className="text-2xl sm:text-3xl font-bold text-center mb-6">Consulta de Correo Electrónico</h1>
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
          {Object.values(SERVICES).map((service) => (
            <div
              key={service.key}
              className={`mb-4 p-4 rounded-lg cursor-pointer transition-colors ${
                selectedService === service ? service.color : "bg-gray-800"
              } hover:bg-opacity-80`}
              onClick={() => setSelectedService(service)}
            >
              <div className="flex items-center">
                <span className="text-2xl mr-3">{service.icon}</span>
                <div>
                  <h3 className="font-bold">{service.name}</h3>
                  <p className="text-sm text-gray-400">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="w-full lg:w-2/3 bg-gray-800 p-6 rounded-lg">
          <h2 className="text-xl font-bold mb-4">
            {selectedService.name} - Consulta de Correo Electrónico
          </h2>
          <div className="mb-4">
            <label className="block mb-2">Correo Electrónico:</label>
            <input
              type="email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              className="w-full p-2 rounded bg-gray-700 text-white"
              placeholder="correo@tudominio.com"
              disabled={isLoading}
            />
          </div>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
            <button
              onClick={searchCode}
              disabled={isLoading || !emailInput}
              className={`${selectedService.color} hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded transition-all duration-300 transform hover:scale-105 disabled:opacity-50 disabled:transform-none`}
            >
              {isLoading ? "Buscando..." : "Buscar Código"}
            </button>
          </div>
        </div>
      </div>

      <Modal isOpen={modalState.isOpen} onClose={closeModal}>
        <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-white">
          Resultado:
        </h3>
        <div className="max-h-[60vh] overflow-y-auto pr-2">
          <pre className="bg-gray-700 p-4 rounded mb-4 whitespace-pre-wrap text-sm sm:text-base">
            {modalState.content.text}
          </pre>
          {modalState.content.code && (
            <button
              onClick={copyToClipboard}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4 transition-all duration-300 transform hover:scale-105 w-full sm:w-auto"
            >
              Copiar Código
            </button>
          )}
          {modalState.content.html && (
            <div>
              <h4 className="font-semibold mb-2 text-white">
                Contenido HTML del correo:
              </h4>
              <div className="bg-gray-700 rounded border border-gray-600 overflow-hidden">
                <div
                  className="html-content-wrapper"
                  dangerouslySetInnerHTML={{ __html: modalState.content.html }}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 sm:h-16 sm:w-16 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}

      <div
        className={`fixed bottom-4 right-4 bg-green-500 text-white px-3 py-2 sm:px-4 sm:py-2 rounded transition-opacity duration-300 text-sm sm:text-base ${
          showNotification ? "opacity-100" : "opacity-0"
        }`}
      >
        Código copiado al portapapeles
      </div>
    </div>
  );
};

export default EmailSearch;