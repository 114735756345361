import React from "react";
import { Helmet } from "react-helmet";
import EmailSearch from "./components/EmailSearch";

function App() {
  return (
    <div className="min-h-screen flex flex-col bg-gray-900 text-white">
      <Helmet>
        <title>Ax Software - Búsqueda de Emails</title>
        <link
          rel="icon"
          href="https://aishiteruxodo.store/wp-content/uploads/2024/09/cropped-LOGO-AX.jpg"
        />
        <meta
          name="description"
          content="Herramienta profesional para búsqueda y verificación de emails."
        />
        <meta
          property="og:image"
          content="https://aishiteruxodo.store/wp-content/uploads/2024/09/cropped-LOGO-AX.jpg"
        />
        <meta
          name="twitter:image"
          content="https://aishiteruxodo.store/wp-content/uploads/2024/09/cropped-LOGO-AX.jpg"
        />
        <meta
          name="description"
          content="Herramienta profesional para búsqueda y verificación de emails."
        />
        <meta
          name="keywords"
          content="email, búsqueda, verificación, Ax Software"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.codestream.click/" />
        <meta property="og:title" content="Ax Software - Búsqueda de Emails" />
        <meta
          property="og:description"
          content="Herramienta profesional para búsqueda y verificación de emails."
        />
        <meta property="og:image" content="https://www.codestream.click/" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.codestream.click/" />
        <meta name="twitter:title" content="Ax Software - Búsqueda de Emails" />
        <meta
          name="twitter:description"
          content="Herramienta profesional para búsqueda y verificación de emails."
        />
        <meta name="twitter:image" content="https://www.codestream.click/" />
      </Helmet>
      <main className="flex-grow container mx-auto px-4 py-4 sm:py-8">
        <div className="mx-auto bg-gray-800 rounded-lg shadow-lg p-4 sm:p-6">
          <h1 className="text-2xl font-bold mb-4">Búsqueda de Emails</h1>
          <EmailSearch />
        </div>
      </main>
      <footer className="bg-gray-800 py-3 sm:py-4 text-center text-sm sm:text-base">
        <p>© 2024 Ax Software. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default App;
