import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: backendURL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  }
});

export default axiosInstance;