import io from 'socket.io-client';
import axiosInstance from './axiosConfig';

const socket = io(process.env.REACT_APP_SOCKET_URL);

export const connectSocket = (onEmailsUpdate) => {
  socket.on('connect', () => {
    console.log('Conectado al servidor de socket');
  });

  socket.on('emailsUpdate', (emails) => {
    onEmailsUpdate(emails);
  });

  socket.on('disconnect', () => {
    console.log('Desconectado del servidor de socket');
  });
};

export const searchEmails = async (query, service) => {
  try {
    const response = await axiosInstance.get('/search-emails', {
      params: { query, service }
    });
    return response.data;
  } catch (error) {
    console.error('Error al buscar emails:', error);
    return [];
  }
};